<script setup  lang="ts">
const {
    isProgressDotsVisible = true,
    isLogoLinked = true,
} = defineProps<{
    isProgressDotsVisible?: boolean;
    isLogoLinked?: boolean;
    assistances?: string[];
}>();

const runtimeConfig = useRuntimeConfig();

function ciao() {
    if (runtimeConfig.public.enableLandingpage) {
        navigateTo('/');
    }
    else {
        navigateTo(runtimeConfig.public.urlJungfrau, { external: true });
    }
};
</script>

<template>
    <header class="px-container-gap pt-5 md:pt-7 print:px-4">
        <div class="flex items-center justify-between">
            <button v-if="isLogoLinked" @click="ciao">
                <Logo />
            </button>
            <Logo v-else />

            <TheProgressDots v-if="isProgressDotsVisible" />
        </div>
    </header>
</template>
